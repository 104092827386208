<template>
  <div id="print">
    <p style="text-align: center; font-weight: 600">开票通知单</p>
    <div style="margin-bottom: 10px">
      <a-row>
        <a-col :span="8" style="display: flex;">
          <div>发票抬头：</div>
          <div style="flex: 1;">{{ orderData.customer }}</div>
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          进销存单号：
          <span v-for="(item, index) in orderData.order_num_list" :key="index">
            {{ item }}
          </span>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          申请日期：{{ orderData.apply_time }}
        </a-col>
      </a-row>
    </div>
    <div>
      <table>
        <thead>
          <tr>
            <th>货物名称</th>
            <th>单位</th>
            <th>规格</th>
            <th>数量</th>
            <th>进货单价</th>
            <th>进货总价</th>
            <th>进货总价（去税）</th>
            <th>出货单价</th>
            <th>出货总价</th>
            <th>供应商</th>
            <th>备注</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in orderList" :key="index">
            <td>{{ item.good_name }}</td>
            <td>{{ item.good_unit }}</td>
            <td>{{ item.good_spec }}</td>
            <td>{{ item.good_num }}</td>
            <td>{{ item.price_in }}</td>
            <td>{{ item.total_price_in }}</td>
            <td>{{ item.total_price_in_tax }}</td>
            <td>{{ item.money }}</td>
            <td>{{ item.total_money }}</td>
            <td>{{ item.supplier_name }}</td>
            <td>{{ item.remark }}</td>
          </tr>
          <tr class="h100" v-if="orderData.paymentInfo">
            <td style="font-weight: bold;">付款方式</td>
            <td colspan="11" style="font-weight: bold;">
              <span style="margin-right: 10px;" v-if="orderData.paymentInfo.cash_statistics !== 0">现金：{{ orderData.paymentInfo.cash_statistics }}</span>
              <span style="margin-right: 10px;" v-if="orderData.paymentInfo.month_change_statistics !== 0">其他：{{ orderData.paymentInfo.month_change_statistics }}</span>
              <p v-for="(detail, index) in orderData.paymentInfo.payment_detail_set" :key="index">{{ detail }}</p>
            </td>
          </tr>
          <tr class="h100" v-if="orderData.paymentInfo && orderData.paymentInfo.agency_fee !== 0">
            <td style="font-weight: bold;">代理费</td>
            <td colspan="11" style="font-weight: bold;">
              <span style="margin-right: 10px;">代理费：{{ orderData.paymentInfo.agency_fee }}</span>
            </td>
          </tr>
          <tr class="h100">
            <td style="font-weight: bold;">备注</td>
            <td colspan="11" style="font-weight: bold;">
              <span>发票类型：{{ GLOBAL.invoiceTypeOps[orderData['invoice_type']].name }} </span>
              <span>{{ orderData.apply_remark }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p id="footer">
      <span>开票人：{{ userInfo.name }}</span><span>申请人：{{ orderData.applicant_name }}</span><span>部门经理：陈瑞雯</span><span>申请时间：{{ date }}</span>
    </p>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: `InvoiceSheetBill`,
  components: {},
  props: {
    currentData: { type: Object, default: null },
    balanceData: { type: Array, default: null }
  },
  created() {
    this.initData();
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    },
    balanceData: {
      handler(val, oldVal) {
        this.initData();
      },
      deep: true
    }
  },
  data() {
    return {
      orderData: {},
      orderList: [],
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date()
    }
  },
  methods: {
    initData() {
      this.orderData = this.currentData
      this.orderList = this.balanceData
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 0px auto;
}

@media print {
  html, body {
    height: inherit;
  }
}

.title{
  width: 100%;
}

.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}

#print { width: 80%; margin: 0 auto; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse }
.h100 { height:100px }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
td:nth-child(1), td:nth-child(2) { text-align: center }
td:nth-child(5) { text-align: right }
td:nth-child(6) { text-align: right }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
