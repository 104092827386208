<template>
  <div id="print">
    <p style="text-align: center; font-weight: 600">{{ data[0].pay_target_name }}应付对账单</p>
    <p style="width: 300px; float: left;">流水号：{{ data[0].record_no }}</p>
    <p style="width: 200px; float: right;">币种：{{ data[0].currency_name }}</p>
    <div>
      <table>
        <tbody>
          <tr>
            <th>接单日期</th>
            <th>业务编号</th>
            <th v-if="type === '国贸部'">报价编号</th>
            <th>应付费用</th>
            <th>合计</th>
          </tr>
          <tr v-for="item in data" :key="item.id">
            <td>{{ moment(item.apply_time).format('YYYY/MM/DD') }}</td>
            <td v-if="type === '国贸部' || type === '进销存'">{{ item.serial_num }}</td>
            <td v-else-if="type === '客服部'">{{ item.cums_serial_num }}</td>
            <td v-else-if="type === '贸易部'">{{ item.sale_serial_num }}</td>
            <td v-if="type === '国贸部'">{{ item.quota_num }}</td>
            <td>
              <table class="table2">
                <tbody>
                  <tr>
                    <td class="w200" v-if="type === '进销存'">{{ item.product_name }}</td>
                    <td class="w200" v-else>{{ item.fee_name }}</td>
                    <td class="w200 moneyStyle">{{ moneyFormatter(item.money) }}</td>
                    <td class="w200">{{ item.pay_target_name }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="moneyStyle">{{ moneyFormatter(item.money) }}</td>
          </tr>
          <tr>
            <td v-if="type === '国贸部'" colspan="3">合计</td>
            <td v-else colspan="2">合计</td>
            <td>
              <table class="table2">
                <tbody>
                  <tr>
                    <td class="w200 moneyStyle">{{ moneyFormatter(money) }}<br></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="moneyStyle">{{ moneyFormatter(money) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'PayStatementBill',
  components: {},
  props: {
    orderData: {
      type: Array,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    type(val, oldVal) {
      this.type = val
    },
    orderData: {
      handler(val, oldVal) {
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      data: [],
      userInfo: this.$store.getters.userInfo,
      year: moment().year(),
      month: moment().month() + 1,
      date: moment().date(),
      money: 0
    }
  },
  methods: {
    moment,
    initData() {
      let money = 0
      this.orderData.forEach(item => {
        money += item.money
      })
      this.money = money
      this.data = this.orderData
    },
    moneyFormatter(money) {
      if (typeof money !== 'number') {
        return ''
      }
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

.moneyStyle {
  text-align: right;
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}
@media print {
  html, body {
    height: inherit;
  }
}
.title{
  width: 100%;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
#print { width: 90%; margin: 0 auto; min-width:1000px; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse; text-align: center; height: 100%; }
table td, th { border: 1px solid #000; }
.w50 { width:50px }
.w100 { width:100px }
.w200 { width:200px }
.table2 { font-size: 12px; }
.table2 tr td:nth-child(1) { border-left: 0; }
.table2 tr td:nth-last-child(1) { border-right: 0; }
.table2 tr:nth-child(1) td{ border-top: 0; }
.table2 tr:nth-last-child(1) td{ border-bottom: 0; }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
