<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款单号">
              <a-input v-model.trim="queryParam.serial_num" placeholder="根据付款单号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单号">
              <a-input v-model.trim="queryParam.order_num" placeholder="根据订单号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务类型">
              <a-select
                allowClear
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="queryParam.order_type"
              >
                <a-select-option v-for="op in orderTypeList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="供应商">
              <a-select
                showSearch
                allowClear
                :filter-option="filterOption"
                placeholder="选择供应商"
                v-model="queryParam.supplier_id"
              >
                <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款状态">
              <a-select
                allowClear
                placeholder="请选择开票状态"
                style="width: 100%"
                v-model="queryParam.fee_status_list"
                mode="multiple"
              >
                <a-select-option v-for="op in feeStatusList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="申请时间">
              <a-range-picker style="width: 100%" show-time @change="onDateChange" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="queryPayments">查询</a-button>
      <a-button type="primary" icon="download" @click="tradeNoPaymentSheet">贸易应付表</a-button>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="index"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data="loadData"
      :alert="{
        show: showFlag,
        msg: showTotal
      }">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <!-- <a @click="printPaymentSheet(record, index)">查看</a> -->
          <!-- <a-divider type="vertical" /> -->
          <!-- <a @click="printDebitSheet(record, index)">付款对账单</a>
          <a-divider type="vertical" /> -->
          <a @click="printPayBillSheet(record, index)">付款单</a>
        </template>
      </span>
      <div slot="orderNumList" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item">{{ item }}</span>
      </div>
      <div slot="feeStatusItem" slot-scope="text, record">
        <a-tag :color="feeStatusColor(record.fee_status)" style="margin-bottom:2px;">
          {{ GLOBAL.feeStatusMaps[record.fee_status] ? GLOBAL.feeStatusMaps[record.fee_status].name : '未提交' }}
        </a-tag>
      </div>
      <div slot="feeItem" slot-scope="text, record">
        <div v-for="item in record.fees" :key="item.id">
          <span v-if="item.pay_target_name">{{ item.pay_target_name }} / {{ item.good_name }} / {{ item.unit }} / {{ item.good_spec }} ~ 数量：{{ item.good_num }} ~ 单价：{{ item.money }} ~ 总价：{{ item.total_money }}</span>
          <span v-else>{{ item.good_name }} / {{ item.unit }} / {{ item.good_spec }} ~ 数量：{{ item.good_num }} ~ 单价：{{ item.money }} ~ 总价：{{ item.total_money }}</span>
        </div>
      </div>
      <div slot="invoiceItem" slot-scope="text, record">
        <div v-for="item in record.pay_invoices" :key="item.id">
          <span>发票号：{{ item.invoice_number }}税额：{{item.tax}}（{{item.tax_rate}}）</span>
        </div>
      </div>
      <div slot="payInfo" slot-scope="text, record">
        <span v-if="record.pay_bank" style="display:block">付款银行：{{ record.pay_bank }}</span>
        <span v-if="record.pay_account" style="display:block">付款账户：{{ record.pay_account }}</span>
        <span v-if="record.pay_date" style="display:block">付款日期：{{ record.pay_date }}</span>
      </div>
    </s-table>
    <a-modal
      :visible="printPaymentSheetVisible"
      :confirmLoading="printPaymentSheetLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPaymentSheetVisible = false">
      <div id="printPaymentSheetBill">
        <InvoiceSheetBill
          :currentData="currentData"
          :balanceData="invoiceData"
        ></InvoiceSheetBill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printPaymentSheetBillBtn"
                  v-print="printPaymentSheetBill">确认打印</a-button>
        <a-button type="primary" @click="printPaymentSheetVisible = false">关闭</a-button>
      </template>
    </a-modal>

    <!-- 付款对账单 -->
    <a-modal
      :visible="printPayStatementsBillModel"
      :confirmLoading="printPayStatementsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款对账单"
      @cancel="printPayStatementsBillModel = false"
    >
      <div id="printStatementsPayBill">
        <pay-statement-bill
          :orderData="printData"
          :type="orderType"
        >
        </pay-statement-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printStatementsPayBtn"
                  v-print="printStatementsPayBill">确认打印</a-button>
        <a-button type="primary" @click="printPayStatementsBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <!-- 付款通知单 -->
    <a-modal
      :visible="printPayBillModel"
      :confirmLoading="printPayBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPayBillModel = false"
    >
      <div id="printPayBill">
        <fms-pay-bill
          :orderList="printPayBillResult"
          :orderData="printPayBillRecord"
        >
        </fms-pay-bill>
      </div>
      <template slot="footer">
        <a-button
          id="printPayBillBtn"
          key="submit"
          type="primary"
          v-print="printPayBill">确认打印
        </a-button>
        <a-button type="primary" @click="printPayBillModel = false">关闭</a-button>
      </template>
    </a-modal>

  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getWmsPaymentFunc, queryPaymentTax } from '@/api/wms/sale'
import { filterOption } from '@/utils/util'
import { getFmsInfoDetail, getchargeTax, getPaymentSheetBill } from '@/api/fms'
import itmsBill from '@/views/fms/bill/FmsItmsBill';
import payStatementBill from '@/views/components/PayStatementBill';
import fmsPayBill from '@/views/fms/bill/FmsPayBill';
import InvoiceSheetBill from '@/views/fms/bill/InvoiceSheetBill';
import { downloadExcel, getSupplier } from '@/api/common'
export default {
  name: 'SalePayment',
  components: {
    STable,
    itmsBill,
    payStatementBill,
    fmsPayBill,
    InvoiceSheetBill
  },
  data() {
    return {
      queryParam: {},
      invoiceData: [],
      currentData: {},
      printPaymentSheetVisible: false,
      printPaymentSheetLoading: false,
      printPaymentSheetBill: {
        id: `printPaymentSheetBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      printPayStatementsBillModel: false,
      printPayStatementsBillModelLoading: false,
      printStatementsPayBill: {
        id: 'printStatementsPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillModel: false,
      printPayBillModelLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      paymentMap: {
        1: { value: 1, name: '转账' },
        2: { value: 2, name: '支票' },
        3: { value: 3, name: '代付' },
        4: { value: 4, name: '打卡' },
        5: { value: 5, name: '现金' },
        6: { value: 6, name: '内部结算' },
        7: { value: 7, name: '免付款' }
      },
      orderTypeList: [
        { value: 0, name: `进库` },
        { value: 2, name: `代销` },
        { value: 4, name: `贸易行政` }
      ],
      feeStatusList: [
        { value: 3, name: `商务待审核` },
        { value: 4, name: `商务审核被驳回` },
        { value: 9, name: `商务通过` },
        { value: 13, name: `已付款` }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '付款单号',
          dataIndex: 'payment_num'
        },
        {
          title: '付款状态',
          dataIndex: 'fee_status',
          scopedSlots: { customRender: 'feeStatusItem' }
        },
        {
          title: `订单号`,
          dataIndex: `order_num_list`,
          scopedSlots: { customRender: 'orderNumList' }
        },
        {
          title: '业务类型',
          dataIndex: 'order_type',
          customRender: (text) => {
            let str = '';
            if (text.includes('0')) {
              str += '进库';
            }
            if (text.includes('2')) {
              str += '代销';
            }
            if (text.includes('4')) {
              str += '贸易行政';
            }
            return str;
          }
        },
        {
          title: '供应商',
          dataIndex: 'customer_name'
        },
        {
          title: '付款方式',
          dataIndex: 'pay_method',
          customRender: text => {
            return this.paymentMap[text] ? this.paymentMap[text].name : ''
          }
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '费用信息',
          dataIndex: 'fees',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '发票信息',
          dataIndex: 'pay_invoices',
          scopedSlots: { customRender: 'invoiceItem' }
        },
        {
          title: '付款信息',
          dataIndex: 'pay_info',
          scopedSlots: { customRender: 'payInfo' }
        },
        {
          title: '创建人',
          dataIndex: 'create_name'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getWmsPaymentFunc('get_wms_payment_page', Object.assign(parameter, this.queryParam)).then(res => {
          var totalTax = 0;
          res.list.forEach(item => {
            item.pay_invoices.forEach(invoice => {
              totalTax = totalTax + invoice.tax
            })
          })
          this.showFlag = true
          this.showTotal = `进项税金额合计：${totalTax}`
              return res
        })
      },
      printData: [],
      orderType: '进销存',
      printPayBillResult: [],
      printPayBillRecord: {},
      supplierOps: [], // 供应商
      showFlag: false,
      showTotal: ''
    }
  },
  created() {
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
    getSupplier({ department: 8 }).then(res => {
      this.supplierOps = res
    })
  },
  methods: {
    filterOption,
    onDateChange(date, dateString) {
      if (dateString[0]) {
        this.queryParam['apply_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['apply_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['apply_start_time'] = null
        this.queryParam['apply_end_time'] = null
      }
    },
    queryPayments() {
      this.$refs.table.refresh(true)
      // this.printData = dataList
      // if (this.queryParam.fee_status_list && this.queryParam.fee_status_list.length > 0) {
      //     var needQueryTax = false
      //     this.queryParam.fee_status_list.forEach(item => {
      //       if (item === 3) {
      //         needQueryTax = true
      //       }
      //     })
      //     if (needQueryTax) {
      //       queryPaymentTax(this.queryParam).then(res => {
      //         this.showFlag = true
      //         this.showTotal = `进项税金额合计：${res}`
      //       })
      //     }
      // }
    },
    // 导出贸易应付表
    tradeNoPaymentSheet() {
      getWmsPaymentFunc('exportTradeNoPaymentSheet').then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      });
    },
    // 付款对账单
    printDebitSheet(record) {
      getWmsPaymentFunc('payFeeBill', { id: record.id }).then(v => {
        this.printData = v;
        this.printPayStatementsBillModel = true;
      });
    },
    // 付款通知单
    printPayBillSheet(record) {
      if (typeof record.audit_id !== 'undefined') {
        getFmsInfoDetail('business_audit', { id: record.audit_id }).then(v => {
          const result = [];
          v.forEach(item => {
            const feeList = item.pay_fee_list;
            if (typeof feeList !== 'undefined') {
              feeList.forEach(fee => {
                result.push({
                  order_num: fee.order_num,
                  product_name: fee.product_name,
                  fee_name: fee.fee_name,
                  amount: fee.amount
                });
              });
            }
          });
          this.printPayBillResult = result;
          record.customer = record.customer_name
          this.printPayBillRecord = record;
          this.printPayBillRecord.apply_remark = record.remark;
          this.printPayBillRecord[`currency_name`] = `CNY`;
          this.printPayBillRecord['invoice_count'] = record.pay_invoices.length
          this.printPayBillModel = true;
        });
      } else {
        this.$message.warning(`当前付款单没有提交商务审核，暂时无法查看付款通知单~`);
      }
    },
    // 打印付款单
    printPaymentSheet(record) {
      if (record.audit_id) {
        getPaymentSheetBill({
          auditRecordId: record.audit_id
        }).then(res => {
          this.invoiceData = res.list;
          record.billType = `付款`;
          record.apply_remark = record.remark;
          this.currentData = record;
          this.currentData['paymentInfo'] = res.paymentInfo;
          this.currentData['department'] = '进销存';
          this.printPaymentSheetVisible = true;
        })
      } else {
        this.$message.error('暂无记录')
      }
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    }
  }
}
</script>

<style scoped>

.balanceTitle{
  font-size: 20px;
  font-weight: 600;
}

</style>
